import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageHessII360: allPagesCompilationYaml(
      filter: { code: { eq: "hessII360" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["telescopeHESSII"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
  }
`

export default function HessII360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageHessII360")
  const imageAVendre = obtenirImages(resultatsRequete, "imageAVendre")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/hessII.jpg"
      altitudeParDefaut={30}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          La Voie Lactée s’étire au-dessus du plus gros télescope optique du
          monde : le HESS II. Avec son miroir de 28 mètres de diamètre, ce
          télescope est une véritable cathédrale de métal, terriblement
          impressionnante quand on se trouve à ses pieds. Situé au cœur du
          désert Namibien, le télescope HESS II étudie les rayonnements gamma
          émis par les astres issus de « catastrophes » cosmiques : supernovas,
          trous noirs, pulsars… afin de mieux les comprendre.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 32 images, rotule panoramique Nodal Ninja 3, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F2, 3200 ISO.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(imageAVendre, "telescopeHESSII")}
            langue={page.langue}
          />
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
